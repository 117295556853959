import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "src/components/layout"

import SEO from "src/components/seo"

import NickelledLogo from "../images/svgs/nickelled-logo-svg.svg"

export default function Chapter(post) {
  const currentPost = post.data.markdownRemark
  const currentGroup = currentPost.frontmatter.reference

  const groupFilter = post.data.allMarkdownRemark.edges.filter(
    edge => edge.node.frontmatter.reference == currentGroup
  )

  const [showChapters, toggleChapters] = useState(false)
  const chapterToggle = () => toggleChapters(!showChapters)

  return (
    <Layout>
      <SEO title={currentPost.frontmatter.title} />
      <div class="h-screen flex overflow-hidden bg-white">
        {
          //<!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
        }
        <div class="md:hidden">
          <div
            className={
              (showChapters ? "opacity-0 hidden" : "opacity-100") +
              " fixed inset-0 flex z-40"
            }
          >
            {
              //<!-- Off-canvas menu overlay, show/hide based on off-canvas menu state.
            }
            <div class="fixed inset-0">
              <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
            </div>
            {
              //<!--   Off-canvas menu, show/hide based on off-canvas menu state.
            }
            <div
              className={
                (showChapters ? "-translate-x-full" : "translate-x-0") +
                " relative flex-1 flex flex-col max-w-xs w-full bg-white"
              }
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={chapterToggle}
                >
                  <span class="sr-only">Close sidebar</span>
                  <svg
                    class="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div class="flex-shrink-0 flex items-center px-4">
                  <NickelledLogo class="h-8 w-auto"></NickelledLogo>
                </div>
                <nav class="mt-5 px-2 space-y-1">
                  {groupFilter.map(({ node }) => (
                    <div key={node.id}>
                      <Link
                        to={node.fields.slug}
                        class="bg-gray-100 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        activeClassName="bg-gray-100 group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-900 text-oOrange"
                      >
                        {node.frontmatter.title}
                      </Link>
                    </div>
                  ))}
                </nav>
              </div>
            </div>

            <div class="flex-shrink-0 w-14">
              {
                //<!-- Force sidebar to shrink to fit close icon -->
              }
            </div>
          </div>
        </div>

        {
          //<!-- Static sidebar for desktop -->
        }
        <div class="hidden md:flex md:flex-shrink-0">
          <div class="flex flex-col w-64">
            <div class="flex flex-col h-0 flex-1">
              <div class="flex-1 flex flex-col overflow-y-auto">
                <nav class="mt-5 flex-1 px-2 bg-white space-y-1">
                  {groupFilter.map(({ node }) => (
                    <div key={node.id}>
                      <Link
                        to={node.fields.slug}
                        class="bg-gray-100 text-gray-900 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        activeClassName="bg-gray-100 group flex items-center px-2 py-2 text-sm font-medium rounded-md text-gray-900 text-oOrange"
                      >
                        {node.frontmatter.title}
                      </Link>
                    </div>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col w-0 flex-1 overflow-hidden">
          <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
            <button
              class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={chapterToggle}
            >
              <span class="sr-only">Open sidebar</span>
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>

          <main
            class="flex-1 relative z-0 overflow-y-auto focus:outline-none"
            tabindex="0"
          >
            <div class="py-6">
              <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <h1 class="text-2xl font-semibold text-gray-900">
                  {currentPost.frontmatter.title}
                </h1>
              </div>

              <div class="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                <div dangerouslySetInnerHTML={{ __html: currentPost.html }} />
              </div>
            </div>
          </main>
        </div>
      </div>
    </Layout>
  )
}

export const post = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        reference
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "multiPager" } } }
      sort: { fields: [frontmatter___order], order: ASC }
    ) {
      nodes {
        frontmatter {
          title
        }
        html
      }
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            reference
          }
        }
      }
    }
  }
`
